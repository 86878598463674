import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { CircularProgress } from '@mui/material';


const HomeInit = lazy(() => import('./components/HomeInit'));
const LandingInit = lazy(() => import('./components/LandingInit'));

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log = function() {}
root.render(
  
  <React.StrictMode>
     
    <Suspense fallback={<CircularProgress />}>
    {/* <HomeInit /> */}
    <LandingInit />
    </Suspense>
    

  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
